import { createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { ElMessageBox, ElMessage } from 'element-plus';
import 'element-plus/es/components/message-box/style/css'
import 'element-plus/es/components/message/style/css'

export default {
  name: "TaskConfig",
  data() {
    return {
      switchLoading: false,
      // 表格定义
      columns: [{
        label: '任务名称',
        prop: 'name'
      }, {
        label: '任务组',
        prop: 'taskGroup'
      }, {
        label: 'cron表达式',
        prop: 'cron'
      }, {
        label: '下次执行时间',
        prop: 'nextTime',
        width: '170px'
      }, {
        label: '最后执行时间',
        prop: 'lastTime',
        width: '170px'
      }, {
        label: '状态',
        width: '100px',
        customRender: props => {
          if (this.$hasPermission(['taskConfig_start', 'taskConfig_stop'])) {
            return _createVNode(_resolveComponent("el-switch"), {
              "modelValue": props.row.status,
              "onUpdate:modelValue": $event => props.row.status = $event,
              "active-value": "在用",
              "inactive-value": "停用",
              "loading": this.switchLoading,
              "before-change": () => {
                return new Promise((resolve, reject) => {
                  this.changeEnabled(props.row, resolve, reject);
                });
              }
            }, null);
          } else {
            return props.row.status;
          }
        }
      }],
      // 顶部按钮
      topButtons: [{
        name: '新增',
        code: 'add',
        type: 'primary',
        click: this.add,
        permissions: ['taskConfig_create']
      } /* {
        name: (selectRows) => {
          if (selectRows && selectRows.length > 1) {
              return '批量删除';
          } else {
              return '删除';
          }
        },
        disabled: (selectRows) => {
          return !selectRows || selectRows.length === 0;
        },
        code: 'remove',
        type: 'danger',
        click: this.remove,
        permissions: ['taskConfig_del']
        }*/],

      // 行按钮
      linkButtons: [{
        name: '修改',
        code: 'edit',
        click: this.edit,
        permissions: ['taskConfig_edit']
      }, {
        name: '删除',
        code: 'del',
        click: this.remove,
        permissions: ['taskConfig_del']
      }, {
        name: '立即执行',
        code: 'run',
        click: this.run,
        permissions: ['taskConfig_run']
      }]
    };
  },
  methods: {
    //改变状态
    changeEnabled(task, resolve, reject) {
      this.switchLoading = true;
      const callBack = res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.$refs.tableRef.fetch();
          return resolve(true);
        } else {
          ElMessage.error(res.message);
          return reject(new Error('Error'));
        }
      };
      if (task.status === '在用') {
        this.$api.business.task.stop(task.className).then(callBack).finally(() => this.switchLoading = false);
      } else {
        this.$api.business.task.start(task.className).then(callBack).finally(() => this.switchLoading = false);
      }
    },
    //添加
    add() {
      this.$router.push('/taskAdd');
    },
    //编辑
    edit(row) {
      this.$router.push({
        path: '/taskAdd',
        query: {
          className: row.className
        }
      });
    },
    //移除
    remove(row) {
      ElMessageBox.confirm('此操作将永久删除该条定时任务, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        this.switchLoading = true;
        const callBack = res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tableRef.fetch();
          } else {
            ElMessage.error(res.message);
          }
        };
        this.$api.business.task.remove(row.className).then(callBack).finally(() => this.switchLoading = false);
      });
    },
    run(row) {
      ElMessageBox.confirm('请确定立即执行该定时任务吗?', '提示', {
        type: 'warning'
      }).then(() => {
        this.switchLoading = true;
        const callBack = res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tableRef.fetch();
          } else {
            ElMessage.error(res.message);
          }
        };
        this.$api.business.task.run(row.className).then(callBack).finally(() => this.switchLoading = false);
      });
    }
  }
};